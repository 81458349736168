<template>
  <b-modal
      id="edit-brand-condition-modal"
      ref="edit-brand-condition-modal"
      cancel-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      cancel-title="Kapat"
      title="İzinli/Yasaklı Marka Listesi Düzenleme"
      size="lg"
  >
    <b-row :class="store.brands_condition==='deny'?'bg-danger':'bg-success'" class="pb-2">
      <b-col cols="6">
        <h6 class="text-white font-weight-bold mb-2 pt-2">
          {{ store.brands_condition === 'deny' ? 'Yasaklı' : 'İzinli' }}
          Marka Listesi (<b>{{ list1.length }}</b>)
        </h6>
        <b-input-group>
          <b-form-input type="text" v-model="searchName1" placeholder="Marka Arama"/>
          <b-input-group-append>
            <b-form-select v-model="selectLimit1" :options="selectOptions"></b-form-select>
          </b-input-group-append>
        </b-input-group>
        <draggable
            :list="list1"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            @add="onAdd"
            @remove="onRemove"
            @choose="onChoose"
        >
          <b-list-group-item v-for="(listItem, index) in getList1" :key="index" tag="li">
            <div class="d-flex">
              <b-avatar :text="listItem.name.slice(0,2)"/>
              <div class="ml-25">
                <b-card-text class="mb-0">
                  {{ listItem.name }}
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
      <b-col cols="6">
        <h6 class="text-white font-weight-bold mb-2 pt-2"> Tüm Marka Listesi (<b>{{ _brands.length }}</b>)</h6>
        <b-input-group>
          <b-form-input type="text" v-model="searchName2" placeholder="Marka Arama"/>
          <b-input-group-append>
            <b-form-select v-model="selectLimit2" :options="selectOptions"></b-form-select>
          </b-input-group-append>
        </b-input-group>
        <draggable :list="getBrands" tag="ul" group="people" class="list-group list-group-flush cursor-move">
          <b-list-group-item v-for="(listItem, index) in getBrands" :key="index" tag="li">
            <div class="d-flex">
              <b-avatar :text="listItem.name.slice(0,2)"/>
              <div class="ml-25">
                <b-card-text class="mb-0">
                  {{ listItem.name }}
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from "@/libs/axios";
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import {mapGetters} from "vuex";
import Vue2Filters from 'vue2-filters'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "EditBrandCondition",
  mixins: [Vue2Filters.mixin],
  components: {
    Prism,
    draggable,
  },
  data: () => ({
    store: {},
    searchName1: '',
    selectLimit1: 10,
    searchName2: '',
    selectLimit2: 10,
    list1: [],
    list2: [],
    selectedItem: {},
    selectOptions: [
      {value: 10, text: 10},
      {value: 20, text: 20},
      {value: 50, text: 50},
    ]
  }),
  computed: {
    ...mapGetters('productBrand', ['_brands']),
    getList1: {
      get() {
        let brands =this.list1;
        return this.limitBy(brands, this.selectLimit1)
      },
      set(value) {
        console.log(value)
      }
    },
    getBrands: {
      get() {
        let brands = this.filterBy(this._brands, this.searchName2, 'name');
        brands = brands.filter(item => !this.list1.some(item1 => item1.id === item.id))
        return this.limitBy(brands, this.selectLimit2)
      },
      set(value) {
        console.log(value)
      }
    }
  },
  methods: {
    onAdd(evt) {
      let item = this.list1[evt.newIndex]
      axios.post(`/store/${this.store.id}/brand/${item.id}`)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: '',
                variant: 'success',
                title: 'Başarılı',
                text: item.name + ' başarıyla eklendi.'
              }
            });
          })
    },
    onRemove(evt) {
      axios.delete(`/store/${this.store.id}/brand/${this.selectedItem.id}`)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: '',
                variant: 'danger',
                title: 'Başarılı',
                text: this.selectedItem.name + ' başarıyla silindi'
              }
            });
          })
    },
    onChoose(evt) {
      this.selectedItem = this.list1[evt.oldIndex]
    },
  }
  ,
  mounted() {
    this.$store.dispatch('productBrand/getAll');
    this.$root.$on('set-store-id', (store) => {
      this.store = store;
      axios.get(`/store/${store.id}/brand`)
          .then(res => {
            this.list1 = res.data.content;
          })
    })
  }
}
</script>

<style scoped>

</style>
